//
//  App
//

.app {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	// Mobile Status
	@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
		overflow: hidden;
	}
}
.rdw-editor-main{
	box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important; 
}
