.card__container {
    margin-left: 0.75rem;
    margin-right: 1.25rem;
    padding: 0;
}

.card__item__container {
    width: 24%;
}

.input__period {
    display: none;
}

.input__period:not(:disabled) ~ .label__period {
    cursor: pointer;
}

.input__period:disabled ~ .label__period {
    color: hsla(150, 5%, 75%, 1);
    border-color: hsla(150, 5%, 75%, 1);
    box-shadow: none;
    cursor: not-allowed;
}

.label__period {
    /* height: 100%; */
    display: block;
    background: white;
    border: 2px solid hsla(150, 75%, 50%, 1);
    border-radius: 20px;
    padding: 1.5rem;
    /* margin-bottom: 1rem; */
    margin: 1rem;
    text-align: center;
    box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
    position: relative;
}

.label__period.darkmode {
    background: #242731;
}

.input__period:checked + .label__period {
    background: hsla(150, 75%, 50%, 1);
    color: hsla(215, 0%, 100%, 1);
    box-shadow: 0px 0px 20px hsla(150, 100%, 50%, 0.75);
}
.input__period:checked + .label__period::after {
    color: hsla(215, 5%, 25%, 1);
    border: 2px solid hsla(150, 75%, 45%, 1);
    /* content: "\f00c"; */
    font-size: 24px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
}

.input__period#control_05:checked + .label__period {
    background: red;
    border-color: red;
}

p {
    font-weight: 900;
}

.payment__register__button {
    /* position: absolute;
    bottom: 0; */
}

.competitor_table {
    width: 100%;
    overflow: scroll;
}
.competitor_table::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 700px) {
    section {
        flex-direction: column;
    }
}
