.pwd__input {
    position: relative;
}
.showpwd__button {
    position: absolute;
    top: 2rem;
    right: 25px;
}
.bg__signup {
    /* background-image: url('../../../assets/img/background-web-1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    background-color: #f7f8fd;
    max-width: 100vw;
    min-height: 100vh;
}
/* .bg__signin{
    background-image: url('../../../assets/img/logo.png');
} */
