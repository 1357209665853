#landing-header {
	/* display: flex;
	justify-content: space-between; */
	width: 100%;
	padding: 1rem 10vw;
	/* background-color: #fff; */
	background-color: #0638a4;
	/* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6); */
	z-index: 1000;
	position: sticky;
	top: 0;
	font-family: "Roboto", sans-serif;
	max-width: 100%;
}
.landing-container {
	width: 100%;
	/* max-width: 1440px; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	/* padding: 20px 80px; */
	/* padding: 6px 115px; */
}
#landing-navbar {
	display: flex;
	align-items: center;
	/* justify-content: center; */
	justify-content: space-between;
	z-index: 1000;
	position: sticky;
	top: 0;
	margin-bottom: 0px;
}
#landing-navbar li {
	list-style: none;
	/* padding: 0 20px; */
	text-decoration: none;
	position: relative;
}
#landing-navbar li a {
	text-decoration: none;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 700;
	/* color: #1a1a1a; */
	color: #fff;
	transition: 0.3s ease;
}
#landing-navbar li a:hover,
#landing-navbar li a.active {
	color: #ff983f;
}
#landing-navbar li a.active::after,
#landing-navbar li a:hover::after {
	content: "";
	width: 30%;
	height: 2px;
	position: absolute;
	background: #ff983f;
	bottom: -4px;
	left: 20px;
}
#mobile {
	display: none;
}
.submenu {
	position: absolute;
	width: 200px;
	background-color: #ffffff;
	box-shadow: 0 20px 45px #00000020;
	/* margin-top: -50px; */
	opacity: 0;
	z-index: -999;
	transition: all ease 0.5s;
	border-radius: 4px;
}
#landing-navbar li:hover .submenu {
	z-index: 99;
	opacity: 1;
	/* margin-top: 10px; */
}
#landing-navbar li:hover .submenu li:first-child {
	padding-top: 10px;
}
#landing-navbar li:hover .submenu li {
	margin-bottom: 10px;
}
#landing-navbar ul .submenu li {
	margin: 0;
	width: 100%;
}
#landing-navbar ul .submenu li a {
	padding: 15px 20px;
	display: inline-block;
	width: 100%;
}
.gradient-button {
	background: linear-gradient(90deg, #ff357d, #ff6f33);
	border-radius: 20px;
}
.landing-logo {
	/* padding-left: 20px; */
	width: 6vw;
	aspect-ratio: 280/115;
}
.notification-button {
	/* background-color: #0638a4; */
	cursor: pointer;
	height: 45px;
	width: 45px;
	margin-right: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.notification-button svg {
	width: 25px;
	height: 25px;
}

.notification-button:hover {
	/* animation: bell 1s ease-out; */
	/* transform-origin: center top; */
}

.notification-title {
	font-weight: 800;
	margin: 0;
}

.notification-body {
	width: 400px;
	max-width: 100vw;
}

.notification-card {
	border-radius: 15px;
	background-color: rgba(238, 238, 238, 0.5);
	padding: 8px;
	margin: 7px 10px;
	cursor: pointer;
}

.notification-type {
	width: 100%;
	aspect-ratio: 1/1;
	border-radius: 15px;
	object-fit: cover;
}

.filter-button-card {
	display: flex;
	gap: 5px;
	/* padding: 1rem 1rem 0; */
}

.filter-button {
	width: 33%;
	min-width: fit-content;
	white-space: nowrap;
}

@keyframes bell {
	0% {
		transform: rotate(17.5deg);
	}
	12.5% {
		transform: rotate(-15deg);
	}
	25% {
		transform: rotate(12.5deg);
	}
	37.5% {
		transform: rotate(-10deg);
	}
	50% {
		transform: rotate(7.5deg);
	}
	62.5% {
		transform: rotate(-5deg);
	}
	75% {
		transform: rotate(2.5deg);
	}
	100% {
		transform: rotate(0);
	}
}

@media (max-width: 1200px) {
	#landing-navbar li a {
		/* font-size: 1vw; */
	}
}

@media (max-width: 1200px) {
	#landing-header {
		display: block;
		/* padding-left: 40px; */
		position: relative;
		/* padding: 0 !important; */
	}
	.landing-container {
		display: block;
		padding-left: 40px;
		/* padding: 10px 20px; */
	}
	#landing-navbar {
		display: block;
		padding: 0%;
		color: #000;
		margin: 0%;
	}
	/* #landing-navbar li {
        margin-bottom: 25px;
    } */
	#landing-navbar li a.active::after,
	#landing-navbar li a:hover::after {
		content: "";
		/* width: 30%; */
		height: 2px;
		position: absolute;
		background: transparent;
		bottom: -4px;
		left: 20px;
	}
	#landing-navbar li {
		display: block;
		list-style: none;
		margin-bottom: 25px;
	}
	#landing-navbar li:first-child {
		margin-top: 30px;
	}
	#landing-navbar li:last-child {
		margin-bottom: 10px;
	}
	.nav-items {
		/* display: inline-block; */
		display: block;
		font-size: 16px;
		/* padding: 0.9em 0.7em; */
		color: #fff;
	}
	#landing-navbar li a {
		color: #fff;
		height: fit-content;
	}
	.landing-navbar-collapse {
		width: 100%;
		overflow: hidden;
		/* text-align: center; */
		height: 0vh;
		transition: all 499ms ease;
		/* background-color: #0d1321; */
		color: #fff;
		/* width: 110vw; */
		/* margin-left: -90px; */
	}
	/* #landing-navbar.active {
        right: 0px;
    } */
	#mobile {
		display: block;
		position: absolute;
		/* top: 25px; */
		top: 13px;
		right: 40px;
	}
	.landing__active {
		height: 411px;
	}
}
@media (max-width: 500px) {
	.landing-container {
		padding-left: 0;
		/* display: flex; */
		align-items: center;
	}

	.landing-logo {
		width: 30%;
	}

	/* #mobile {
		position: relative;
		top: unset;
		right: unset;
	} */
}
