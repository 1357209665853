.layer__container {
	position: absolute;
	z-index: 100;
	height: 100%;
	/* width: 100%; */
	overflow: hidden;
	background: rgba(255, 255, 255, 255);
	opacity: 0.8;
	filter: blur(1.6px);
	margin: auto;
	top: 0;
	left: 1.5rem;
	bottom: 1rem;
	right: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 997;
	border-radius: 1rem;
}
.layer__modal__container{
    position: absolute;
	z-index: 100;
	height: 100%;
	/* width: 100%; */
	overflow: hidden;
	/* background: rgba(255, 255, 255, 255); */
	/* opacity: 0.8; */
	margin: auto;
	top: 0;
	left: 1.5rem;
	bottom: 1rem;
	right: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 998;
	border-radius: 1rem;
}
.layer__modal{
    min-width: 200px;
    min-height: 120px;
    padding: 20px;
	background: rgba(255, 255, 255, 255);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid hsla(230, 14%, 91%, 1);
    border-radius: 10px;
	z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
	position: fixed;
	top: 30vh;
}
