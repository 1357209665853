.workspace__avatar {
    border-radius: 50%;
    object-fit: cover;
}

.notifications__number {
    position: absolute;
    right: 15px;
    top: 5px;
    background-color: red;
    border-radius: 5px;
    color: white;
    font-size: 12px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 15;
    cursor: pointer;
    padding: 0 0.45rem;
}

.unread__notifications {
    width: 10px;
    aspect-ratio: 1/1;
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    border-radius: 50%;
    z-index: 9999;
}

@media screen and (max-width: 769px) {
    .notifications__number {
        font-size: 9px;
    }
}
