.facebook__login__button {
    font-family: Helvetica,sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: calc(.06887vw + .67769px) solid #4c69ba;
    padding: calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px);
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 16px;
}
.facebook__login__button i {
    margin-right: 10px;
}
.instagram__login__button {
    font-family: Helvetica,sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    /* display: inline-block; */
    display: flex;
    align-items: center;
    justify-content: center ;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #E33E5C;
    /* border: calc(.06887vw + .67769px) solid #4c69ba; */
    padding: calc(.34435vw + 13.38843px) calc(.34435vw + 18.38843px);
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 16px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.1)

}
