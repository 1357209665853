.login-body {
    color: #000;
}
.logo__login{
    position: absolute;
    top: 3vh;
    left: 5vw;
    z-index: 100000;
    cursor: pointer;
}
.auth-input-form input {
    background-color: #f8f9fa !important;
    color: #000 !important;
    border: none;
}

.auth-heading {
    font-weight: 800;
    font-size: 2.2vw;
    text-align: center;
    margin-bottom: 2rem;
}

.auth-input-form {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.auth-form input {
    background-color: #f1f2f5;
    padding: 0.7rem 1.5rem;
    font-size: 14px;
}

.auth-action-button {
    width: 60%;
    padding: 0.75rem auto;
    font-size: 16px;
}

.change-status-button {
    border: none;
    background-color: transparent;
    color: #0638a4;
    /* font-weight: 500; */
}

.forgot-password-link {
    text-decoration: none;
    color: #0638a4 !important;
    font-size: 500;
}

.forgot-password-link:hover {
    text-decoration: underline;
}

.auth-label {
    margin: 0 0 5px 0;
    color: #aeaeae;
}

.image-div {
    /* padding-top: 15vh; */
    position: relative;
}

.auth-image {
    width: 80%;
    margin-left: 10%;
}

.amai-logo {
    position: absolute;
    bottom: -5%;
    right: 15%;
    width: 35%;
}

@media only screen and (max-width: 1000px) {
    .login-body {
        /* flex-direction: column-reverse; */
        justify-content: center;
        align-items: center;
    }

    .auth-input-form {
        border-radius: 2rem;
        margin: 2rem 0;
        padding: 2rem;
    }

    .image-div {
        padding-top: 5rem;
    }
}

@media only screen and (max-width: 600px) {
    .image-div {
        padding-top: 2rem;
    }

    .auth-input-form {
        border-radius: 2rem;
        margin: 2rem 0.5rem;
    }

    .auth-heading {
        font-size: 32px;
    }

    .auth-input-form .col-8 {
        width: 90%;
    }
}
