.about__us__container {
	background-color: #fffefa;
	/* margin: 0 auto; */
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
}

/* .about__us__section1,
.about__us__section2,
.about__us__section3,
.about__us__section4,
.about__us__section5,
.about__us__section6 {
	padding: 0 7%;
} */

.about__us__section1 {
	/* min-width: 1200px; */
	max-width: 1440px;
	padding-top: 80px;
	padding-bottom: 80px;
}
.about__us__section1 img {
	/* max-width: 720px; */
	max-width: 100%;
	max-height: 502px;
	object-fit: cover;
}
.about__us__section1__header {
	font-weight: 400;
	font-size: 64px;
	line-height: 78px;
}
.about__us__section1__sub__header {
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
}
.about__us__section1__header__line {
	height: 1px;
	width: 130px;
	margin-left: 10px;
	background-color: black;
}
.about__us__section1__description {
	/* font-weight: 500; */
	font-size: 16px;
	/* line-height: 22px; */
}

.about__us__section1__image {
	border-radius: 5px;
}

/* .about__us__section1__button {
	color: #fff;
	padding: 10px 30px;
	background-color: #39b086;
	border-radius: 6px;
	width: fit-content;
	border: none;
	font-size: 1.5rem;
} */

.about__us__section1__button {
	display: inline-block;
	width: fit-content;
	padding: 0.75rem 3rem;
	border: none;
	border-radius: 10rem;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.5rem;
	letter-spacing: 0.15rem;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	cursor: pointer;
}
.about__us__section1__button:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #39b086;
	border-radius: 10rem;
	z-index: -2;
}
.about__us__section1__button:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background-color: #2f8f6d;
	transition: all 0.3s;
	border-radius: 10rem;
	z-index: -1;
}
.about__us__section1__button:hover {
	color: #fff;
}
.about__us__section1__button:hover:before {
	width: 100%;
}

/*  */

.aboutUs__button {
	height: 4.5rem;
}

.aboutUs__submitButton,
.aboutUs__submitButton:visited {
	text-transform: uppercase;
	text-decoration: none;
	padding: 15px 40px;
	display: inline-block;
	border-radius: 100px;
	transition: all 0.2s;
	position: absolute;
}

.aboutUs__submitButton:hover {
	transform: translateY(-3px);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.aboutUs__submitButton:active {
	transform: translateY(-1px);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.aboutUs__submitButton::after {
	content: "";
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 100px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	transition: all 0.4s;
}

.aboutUs__submitButton:hover::after {
	transform: scaleX(1.4) scaleY(1.6);
	opacity: 0;
}

.aboutUs__submitButton-animated {
	animation: moveInBottom 2s ease-out;
	animation-fill-mode: backwards;
}

@keyframes moveInBottom {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

/*  */

.about__us__section2 {
	max-width: 1440px;
	margin-top: 120px;
	margin-bottom: 120px;
	background-color: #022c5d;
	border-radius: 20px;
	color: #fff;
	padding: 50px 30px 20px 30px;
}
.about__us__section2 > div {
	padding: 0 10%;
}

.about__us__section2__img {
	width: 60%;
	height: auto;
	border-radius: 50%;
	object-fit: cover;
}
.about__us__section3 {
	max-width: 1440px;
	padding-top: 80px;
	padding-bottom: 80px;
}
.about__us__section3 img {
	max-width: 100%;
	height: auto;
	object-fit: cover;
}
.about__us__section3__description {
	font-size: 18px;
	font-weight: 400;
}
.about__us__section3__description__header {
	font-weight: 700;
	color: #09be96;
	text-transform: uppercase;
}
.about__us__section4 {
	/* min-width: 1200px; */
	max-width: 1440px;
	padding-top: 80px;
	padding-bottom: 80px;
	font-size: 16px;
}
.about__us__section4__title {
	font-weight: 500;
	color: #09be96;
}
.about__us__section5 {
	max-width: 1440px;
	margin-top: 120px;
	margin-bottom: 120px;
	background-color: #022c5d;
	border-radius: 20px;
	color: #fff;
	padding: 50px 10% 20px;
	width: 100%;
	display: flex;
	gap: 3%;
	flex-direction: column;
	align-items: center;
	font-size: 24px;
}

.section5__placeholderImage {
	width: 70%;
	border-radius: 50%;
	text-align: center;
}

.about__us__section5 input {
	margin-bottom: 1rem;
}

.about__us__section5 input,
.about__us__section5 textarea {
	width: 100%;
	font-size: 16px;
	border-radius: 8px;
	box-shadow: none !important;
	outline: none !important;
	padding: 8px 10px;
	border: none;
	background-color: #fffdf6;
}

.aboutUs__submitButton {
	background-color: #f14462;
	border-radius: 5rem;
	color: #fffdf6;
	box-shadow: none;
	border: none;
	padding: 0.75rem 5rem;
	width: fit-content;
}

/* .about__us__section5 input::placeholder,
.about__us__section5 textarea::placeholder {
	color: #e6e6e6 !important;
} */

.contact-with-us-form-input {
}

.about__us__section5__button {
	background-color: #ff0000;
	border-radius: 10px;
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
	align-items: center;
	cursor: pointer;
}
.about__us__section6 {
	/* min-width: 1200px; */
	max-width: 1440px;
	width: 100%;
	padding-top: 80px;
	padding-bottom: 80px;
	font-size: 16px;
}
.about__us__section6 img {
	width: 100px;
	height: auto;
}
.about__us__section6__card {
	background-color: #fff;
	height: 100%;
	/* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding: 30px 15px 15px 15px;
	border-radius: 5px;
}
.about__us__container__moblie {
	padding-left: 10px;
	padding-right: 10px;
}
.about__us__container__moblie .about__us__section1 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.about__us__container__moblie .about__us__section2 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.about__us__container__moblie .about__us__section3 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.about__us__container__moblie .about__us__section4 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.about__us__container__moblie .about__us__section5 {
	padding-top: 40px;
	padding-bottom: 40px;
}
.affiliate__container {
	background-color: rgba(224, 242, 254, 1);
	/* margin: 0 auto; */
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
}
.affiliate__container__mobile {
	padding-left: 10px;
	padding-right: 10px;
	background-color: rgba(224, 242, 254, 1);
	/* margin: 0 auto; */
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: stretch;
}
.affiliate__section1 {
	max-width: 1440px;
	padding-top: 80px;
	padding-bottom: 80px;
	width: 70%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: rgba(0, 42, 122, 1);
	/* font-size: 20px; */
}
.affiliate__section2 {
	max-width: 1440px;
	padding-top: 80px;
	padding-bottom: 80px;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: rgba(0, 42, 122, 1);
}
.affiliate__section2 img {
	width: 40%;
}
.affiliate__container__mobile .affiliate__section1 {
	width: 100%;
	padding-top: 40px;
	padding-bottom: 40px;
}
.affiliate__container__mobile .affiliate__section2 {
	width: 100%;
	padding-top: 40px;
	padding-bottom: 40px;
}
.affiliate__container__mobile .affiliate__section3 {
	width: 100%;
	padding-top: 40px;
	padding-bottom: 40px;
}
.affiliate__section3 {
	max-width: 1440px;
	padding-top: 80px;
	padding-bottom: 80px;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: rgba(0, 42, 122, 1);
	font-size: 16px;
}
.affiliate__section3 img {
	width: 20%;
	height: 150px;
}
.about__us__section3__text__description {
	font-style: italic;
	font-weight: 400;
	color: rgba(74, 26, 181, 1);
	text-align: center;
}

.about__us__section3 {
	/* max-width: 100vw; */
	overflow: hidden;
}

.affiliate__image {
	width: 100%;
}

.text-justify {
	text-align: justify;
}

@media only screen and (max-width: 1440px) and (min-width: 900px) {
	.about__us__container,
	.affiliate__section2 {
		padding: 0 2.5rem;
	}

	.affiliate__section3 {
		font-size: 14px;
	}

	.about__us__section1,
	.about__us__section2,
	.about__us__section3,
	.about__us__section4,
	.about__us__section5,
	.about__us__section6,
	.affiliate__section1,
	.affiliate__section2,
	.affiliate__section3,
	.about__us__container {
		padding: 80px 7%;
	}

	.about__us__section6__card,
	.about__us__section6,
	.about__us__section3__description {
		font-size: 14px;
	}

	.about__us__section3__description__header {
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.about__us__section2 > div {
		padding: 0;
	}

	.about__us__section5 {
		flex-direction: column;
		padding: 2.5%;
		gap: 1rem;
	}

	.about__us__section1,
	.about__us__section2,
	.about__us__section3,
	.about__us__section4,
	.about__us__section5,
	.about__us__section6,
	.affiliate__section1,
	.affiliate__section2,
	.affiliate__section3,
	.about__us__container {
		padding: 2.5%;
	}
}
