/* svg.tea {
	--secondary: #33406f;
}
svg.tea #teabag {
	transform-origin: top center;
	transform: rotate(3deg);
	animation: swing 2s infinite;
}
svg.tea #steamL {
	stroke-dasharray: 13;
	stroke-dashoffset: 13;
	animation: steamLarge 2s infinite;
}
svg.tea #steamR {
	stroke-dasharray: 9;
	stroke-dashoffset: 9;
	animation: steamSmall 2s infinite;
}
@-moz-keyframes swing {
	50% {
		transform: rotate(-3deg);
	}
}
@-webkit-keyframes swing {
	50% {
		transform: rotate(-3deg);
	}
}
@-o-keyframes swing {
	50% {
		transform: rotate(-3deg);
	}
}
@keyframes swing {
	50% {
		transform: rotate(-3deg);
	}
}
@-moz-keyframes steamLarge {
	0% {
		stroke-dashoffset: 13;
		opacity: 0.6;
	}
	100% {
		stroke-dashoffset: 39;
		opacity: 0;
	}
}
@-webkit-keyframes steamLarge {
	0% {
		stroke-dashoffset: 13;
		opacity: 0.6;
	}
	100% {
		stroke-dashoffset: 39;
		opacity: 0;
	}
}
@-o-keyframes steamLarge {
	0% {
		stroke-dashoffset: 13;
		opacity: 0.6;
	}
	100% {
		stroke-dashoffset: 39;
		opacity: 0;
	}
}
@keyframes steamLarge {
	0% {
		stroke-dashoffset: 13;
		opacity: 0.6;
	}
	100% {
		stroke-dashoffset: 39;
		opacity: 0;
	}
}
@-moz-keyframes steamSmall {
	10% {
		stroke-dashoffset: 9;
		opacity: 0.6;
	}
	80% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
	100% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
}
@-webkit-keyframes steamSmall {
	10% {
		stroke-dashoffset: 9;
		opacity: 0.6;
	}
	80% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
	100% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
}
@-o-keyframes steamSmall {
	10% {
		stroke-dashoffset: 9;
		opacity: 0.6;
	}
	80% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
	100% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
}
@keyframes steamSmall {
	10% {
		stroke-dashoffset: 9;
		opacity: 0.6;
	}
	80% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
	100% {
		stroke-dashoffset: 27;
		opacity: 0;
	}
} */
.loader {
	text-align: center;
	vertical-align: middle;
	position: relative;
	display: flex;
	/* background: white; */
	/* padding: 150px; */
	/* box-shadow: 0px 40px 60px -20px rgba(0, 0, 0, 0.2); */
}
.loader div {
	display: block;
	font-size: 32px;
	font-weight: 700;
}

.loader div:nth-child(2) {
	color: #0cd0af;
}

.loader div:nth-child(3) {
	color: #0638a4;
}

.loader div:nth-child(4) {
	color: #eb3223;
}

/* .loader div {
	animation: animate 1.5s linear infinite;
} */


/* .loader div:last-child {
	animation: jump 1.5s ease-in-out infinite;
} */

.loader span {
	display: block;
	width: 20px;
	height: 20px;
	background: #eee;
	border-radius: 50%;
	margin: 0 5px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.loader span:nth-child(2) {
	background: #0cd0af;
}

.loader span:nth-child(3) {
	background: #0638a4;
}

.loader span:nth-child(4) {
	background: #eb3223;
}

.loader span:not(:last-child) {
	animation: animate 1.5s linear infinite;
}

@keyframes animate {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(30px);
	}
}

.loader span:last-child {
	animation: jump 1.5s ease-in-out infinite;
}

@keyframes jump {
	0% {
		transform: translate(0, 0);
	}
	10% {
		transform: translate(10px, -10px);
	}
	20% {
		transform: translate(20px, 10px);
	}
	30% {
		transform: translate(30px, -50px);
	}
	70% {
		transform: translate(-150px, -50px);
	}
	80% {
		transform: translate(-140px, 10px);
	}
	90% {
		transform: translate(-130px, -10px);
	}
	100% {
		transform: translate(-120px, 0);
	}
}
