.forgot-password-body {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    background-color: #f7f8fd;
}

.forgot-password-form {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
}

.forgot-password-form input {
    width: 100%;
    background-color: #f1f2f5;
}

.forgot-password-action-button {
    width: 40%;
    margin: 1rem auto 0;
    min-width: fit-content;
}

@media screen and (max-width: 1000px) {
    .forgot-password-body {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding-top: 5rem;
    }

    .forgot-password-form {
        width: 80%;
    }
}

@media screen and (max-width: 600px) {
    .forgot-password-body {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding-top: 5rem;
    }

    .forgot-password-form {
        width: 90%;
    }
}
