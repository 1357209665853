//
// Custom
//

@use 'sass:map';
html {
    overflow: scroll;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: transparent;
}
// body.modal-open {
// 	// overflow: hidden;
//   background: rgb(255 255 255 / 40%);
//   // z-index: 2;
//   backdrop-filter: blur(20px);
//   }
  
  // body:has(.dropdown-menu) {
	// overflow: hidden;
	// /* CSS rules khi body không chứa class show */
  // }
.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}
.aside-touch{
  display: none;
}
.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}
.table__active{
  background-color: rgb(237,247,255);
  font-weight: bolder;
}
.table__active__dark{
  background-color: rgb(237,247,255);
  font-weight: bolder;
  color: black;
}