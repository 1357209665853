.footer-landing {
    background-color: #022c5d;
    display: flex;
    gap: 5vw;
    padding: 5rem 5vw;
}

.footer-items {
    width: 25%;
    color: white;
}

.footer-logo {
    width: 6vw;
    aspect-ratio: 86/35;
    cursor: pointer;
}

.footer-heading {
    font-size: 20px;
    font-weight: 800;
}

.footer-items > p > a {
    color: white;
    text-decoration: none;
    font-size: 14px;
    transition: all 0.5s;
    margin-bottom: 0.5rem;
}

.footer-items > p > a:hover {
    font-size: 15px;
    transition: all 0.5s;
}

@media only screen and (max-width: 600px) {
    .footer-landing {
        flex-wrap: wrap;
        gap: 5%;
    }

    .footer-items {
        width: 47.5%;
        margin-bottom: 1.5rem;
    }

    .footer-logo {
        width: 30vw;
    }
}
